import React from "react";
import { motion } from "framer-motion";
import { navLinks } from "../data";

import Logo from "../assets/flous_logo.PNG";

const Navbar = () => {
  return (
    <nav className="own-navbar">
      <div className="container">
        <div className="row">
          <nav role="navigation" className="nav-menu w-nav-menu">
            <div className="cropper">
              <a
                href="/"
                className="brand w-nav-brand w--current text-white fs-1 logo-text"
              >
                <motion.img
                  src={Logo}
                  className="img-fluid logoImg"
                  alt="flous"
                  width={120}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                />
              </a>
            </div>
            <div className="nav-link-wrapper">
              {navLinks.map((link, index) => (
                <motion.a
                  key={link.label}
                  href={link.href}
                  className="nav-link navLink w-nav-link fs-4 fw-semibold text-black"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.1,
                  }}
                >
                  {link.label}
                </motion.a>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";
import { motion } from "framer-motion";

import First from "../assets/first.png";
import Soon from "../assets/coming-soon.png";
import Appstore from "../assets/appstore.png";
import Googleplay from "../assets/googleplay.png";

const Header = () => {
  return (
    <header className="header overflow wf-section">
      <div className="container w-container align-items-center">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6">
            <motion.h1
              className="text-white fs-1 mt-lg-0 mb-5 headerTitle"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.1 }}
            >
              Integrated Payment and Collection Gateway For the Micro, Small,
              and Medium Ecosystem
            </motion.h1>
            <motion.p
              className="text-white fs-5 fw-semibold fst-italic headerDesc"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              Flous enables seamless funds movement between cash and digital
              channels, offering unmatched convenience for the MSM sector, and
              driving financial inclusion across formal and informal economies.
            </motion.p>

            <div className="mt-5 py-2 w-fit d-flex align-items-center justify-content-between">
              <span className="text-white fw-semibold">
                We Finalized the mvp and we are ready for market launch, <br />
              </span>
            </div>

            <div className="d-flex align-items-center">
              <span className="text-ava font-semibold text-nowrap me-2">
                soon it will be available on
              </span>

              <div className="d-flex flex-wrap gap-2 align-items-center mt-1">
                <img
                  src={Appstore}
                  alt="appleStore"
                  width={165}
                  // height={124}
                  className="storeImgs"
                />

                <img
                  src={Googleplay}
                  alt="googlePlay"
                  width={183}
                  // height={154}
                  className="storeImgs"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-lg-flex text-start">
            <motion.img
              src={First}
              alt="flous"
              className="img-fluid rounded-3 filter-img"
              height={250}
              width={250}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5 }}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import DowImg from "../assets/download.png";

const Download = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <section ref={ref} className="download py-2 py-lg-5" id="community">
      <div className="container my-2 my-lg-5">
        <div className="row justify-content-center align-items-center download-content m-auto">
          <motion.h5
            className="text-black fs-1 fw-bold text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            We facilitate cash-to-cash, cash-to-digital, digital-to-cash, and
            digital-to-digital transactions
          </motion.h5>

          {/* <motion.p
            className="headerDesc service-desc text-muted mt-3 mb-5 text-center fw-semibold fst-italic fs-5"
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.4 }} 
          >
            We finalized the MVP and we are ready for market launch.
          </motion.p> */}

          <motion.img
            src={DowImg}
            alt="flous"
            width={220}
            className="img-fluid"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={isVisible ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 0.5, delay: 0.6 }}
          />
        </div>
      </div>
    </section>
  );
};

export default Download;

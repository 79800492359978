import React from "react";
import RecogImg from "../assets/recog.png";

const Recognition = () => {
  return (
    <section className="recognition py-2 py-lg-5" id="recognition">
      <div className="container justify-content-center">
        <h5 className="fs-1 mb-4 pb-4 text-center">Recognition</h5>
        <img src={RecogImg} alt="recognition" className="img-fluid mb-4" />
      </div>
    </section>
  );
};

export default Recognition;

import Serv1 from "../assets/serv1.png";
import Serv2 from "../assets/serv2.png";
import Serv3 from "../assets/serv3.png";
import Serv4 from "../assets/serv4.png";

export const navLinks = [
  { href: "#services", label: "Value" },
  { href: "#pays", label: "Service" },
  { href: "#recognition", label: "Recognition" },
];

export const servicesIcons = [
  {
    imgSrc: Serv1,
    title: "Integrating Cash For Payment and Collection",
  },
  {
    imgSrc: Serv2,
    title: "Facilitating B2B Trade Payments and Collections",
  },
  {
    imgSrc: Serv3,
    title: "Providing In-Store and On-Delivery Acceptance",
  },
  {
    imgSrc: Serv4,
    title: "Facilitating Remote and Online Payments",
  },
  {
    imgSrc: Serv1,
    title: "Enabling Credit and Financing Options",
  },
  {
    imgSrc: Serv4,
    title: "Supporting Recurring Payment Options",
  },
];

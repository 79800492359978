import React from "react";

const Footer = () => {
  const currentDate = new Date();

  return (
    <footer className="py-2 py-lg-5 footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="text-center fs-4 headerDesc">© {currentDate.getFullYear()} Flous</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

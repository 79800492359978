import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import Pay from "../assets/pay.png";

const Feature2 = () => {
  const [isVisible, setIsVisible] = useState(false); 
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); 
          observer.disconnect();
        }
      },
      { threshold: 0.1 } 
    );

    if (ref.current) {
      observer.observe(ref.current); 
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); 
      }
    };
  }, [ref]);

  return (
    <section ref={ref} className="pays bg-white py-2 py-lg-5" id="pays">
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 mb-5 mb-lg-0 text-start">
            <motion.h3
              className="text-black fs-1 fw-bold"
              initial={{ opacity: 0, y: 20 }} 
              animate={isVisible ? { opacity: 1, y: 0 } : {}} 
              transition={{ duration: 0.5 }} 
            >
              Manage your regular payments and collections easily
            </motion.h3>

            <motion.p
              className="text-body-tertiary fw-bold fst-italic my-4 fs-5 headerDesc"
              initial={{ opacity: 0, y: 20 }}
              animate={isVisible ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.2 }} 
            >
              Flous offers unparalleled convenience to streamline your payment
              and collection needs, allowing you to choose your preferred
              options for sending and receiving funds.
            </motion.p>

            <div className="row w-100 mt-5">
              <motion.div
                className="col-md-4 servHead mb-3 mb-md-0 card shadow border-0 p-4 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.4 }} 
              >
                <p className="fs-4 border-bottom border-muted p-2 bg-green rounded-2 head-title">
                  Banked
                </p>
                <ul className="list-unstyled">
                  <li className="pb-3 fs-4 border-bottom">✓ Debit</li>
                  <li className="pt-3 fs-4">✓ Credit</li>
                </ul>
              </motion.div>

              <motion.div
                className="col-md-4 mb-3 mb-md-0 card servHead shadow border-0 p-4 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.6 }} 
              >
                <p className="fs-4 border-bottom border-muted p-2 bg-green rounded-2 head-title text-nowrap">
                  Semi-Banked
                </p>
                <ul className="list-unstyled">
                  <li className="pb-3 fs-4 border-bottom">✓ E-Wallet</li>
                  <li className="pt-3 fs-4">✓ Prepaid</li>
                </ul>
              </motion.div>

              <motion.div
                className="col-md-4 card servHead shadow border-0 p-4 text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.8 }} 
              >
                <p className="fs-4 border-bottom border-muted p-2 bg-green rounded-2 head-title">
                  Unbanked
                </p>
                <ul className="list-unstyled">
                  <li className="fs-4">✓ Cash</li>
                </ul>
              </motion.div>
            </div>
          </div>

          <motion.div
            className="col-12 col-lg-6 mb-5 mb-lg-0 text-center"
            initial={{ opacity: 0, scale: 0.8 }} 
            animate={isVisible ? { opacity: 1, scale: 1 } : {}} 
            transition={{ duration: 0.5, delay: 1 }} 
          >
            <img
              src={Pay}
              alt="feature-img"
              className="img-fluid rounded-4 pay-img"
              width={300}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Feature2;

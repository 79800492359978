import React from "react";
import {
  Download,
  Feature2,
  Footer,
  Header,
  Navbar,
  Recognition,
  Services,
} from "./components";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Services />
      <Feature2 />
      <Download />
      <Recognition />
      <Footer />
    </div>
  );
}

export default App;

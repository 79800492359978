import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { servicesIcons } from "../data";

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <section className="services-bg py-2 py-lg-5" id="services" ref={ref}>
      <div className="container my-2 my-lg-5">
        <div className="row justify-content-center">
          <h2 className="text-center fs-1">Disruption without Interruption</h2>
          <p className="headerDesc mt-3 text-center service-desc fw-semibold fst-italic fs-5">
            Flous offers innovative solutions for banked and unbanked
            individuals and entities, both formal and informal, enabling
            game-changing use cases within the MSM ecosystem.
          </p>
        </div>

        <div className="row gap-4 justify-content-center mt-5">
          {servicesIcons.map((service, index) => (
            <motion.div
              key={index}
              className="serv-card col-md-5 col-lg-3 card px-5 py-4 border-0 shadow-sm rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={isVisible ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <img
                src={service.imgSrc}
                alt="service"
                className="img-fluid mb-0 mb-md-4"
                width={70}
              />
              <h3 className="headDesc">{service.title}</h3>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
